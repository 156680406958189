import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";

const CoreValue = ({ title, children }) => (
  <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow">
    <h3 className="text-xl font-bold text-primeColor mb-3">{title}</h3>
    <p className="text-lightText">{children}</p>
  </div>
);

const About = () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");

  useEffect(() => {
    setPrevLocation(location.state?.data);
  }, [location]);

  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="About" prevLocation={prevLocation} />
      
      {/* Hero Section */}
      <div className="py-12 text-left">
        <h1 className="text-4xl font-bold mb-6">
          Hi, we're{" "}
          <span className="text-primeColor">Morethan</span>
          <span className="text-green-500">SPEC</span>
        </h1>
        <p className="max-w-2xl text-lightText mb-8">
          A marketplace for sustainable electronics that links consumers with certified sellers, 
          offering a wide range of items at great prices. What makes Morethaspec special, besides 
          the focus on sustainability, is its strict quality standards and thorough certification 
          process, ensuring every product meets or exceeds customer expectations.
        </p>
        <Link to="/shop">
          <button className="w-52 h-10 bg-primeColor text-white hover:bg-black duration-300">
            Explore Our Products
          </button>
        </Link>
      </div>

      {/* Mission & Vision Section */}
      <div className="grid md:grid-cols-2 gap-8 py-12">
        <div className="bg-gray-50 p-8 rounded-lg">
          <h2 className="text-2xl font-bold text-primeColor mb-4">Our Mission</h2>
          <p className="text-lightText">
            To empower individuals and businesses by providing a trusted platform for buying 
            and selling refurbished tech products. We are committed to reducing environmental 
            impact through innovative recycling and refurbishment processes, ensuring tech 
            products are revitalized to extend their lifecycle and minimize waste.
          </p>
        </div>
        <div className="bg-gray-50 p-8 rounded-lg">
          <h2 className="text-2xl font-bold text-primeColor mb-4">Our Vision</h2>
          <p className="text-lightText">
            To lead the tech industry towards sustainability and environmental stewardship 
            through our platform. We envision a future where every refurbished tech product 
            contributes to a cleaner planet and enhanced technological access.
          </p>
        </div>
      </div>

      {/* Core Values Section */}
      <div className="py-12">
        <h2 className="text-3xl font-bold text-center mb-12">Our Core Values</h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          <CoreValue title="Trust">
            We ensure transparency by providing clear and detailed information about each 
            device's condition, history, and specifications. Our commitment to honesty means 
            you can trust that the device you purchase will match its description.
          </CoreValue>

          <CoreValue title="Accountability">
            We hold ourselves to the highest standards of accountability through detailed 
            reports, transparent processes, and dedicated customer support, backed by years 
            of industry experience and proper certifications.
          </CoreValue>

          <CoreValue title="Innovation">
            We constantly improve our services by integrating new technologies and enhancing 
            customer experience, staying ahead of industry trends while maintaining our high 
            standards.
          </CoreValue>

          <CoreValue title="Commitment to Customers">
            Our passion drives us to exceed expectations, maintain humility, and continuously 
            learn from feedback. We back our commitment with a money-back guarantee and 
            educational resources.
          </CoreValue>

          <CoreValue title="Quality">
            We ensure genuity by using only original parts and maintaining OEM standards, 
            with meticulous attention to detail in every aspect of our refurbishment process.
          </CoreValue>

          <div className="bg-green-50 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow">
            <h3 className="text-xl font-bold text-green-600 mb-3">Sustainability</h3>
            <p className="text-lightText">
              Our commitment to the environment drives everything we do, from our 
              refurbishment processes to our packaging choices, creating a circular 
              economy for technology.
            </p>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="text-center py-12 bg-gray-50 rounded-lg mb-12">
        <h2 className="text-2xl font-bold mb-4">Ready to Make a Difference?</h2>
        <p className="text-lightText mb-6">
          Join us in our mission to make technology more sustainable and accessible.
        </p>
        <Link to="/shop">
          <button className="w-52 h-10 bg-primeColor text-white hover:bg-black duration-300">
            Shop Now
          </button>
        </Link>
      </div>
    </div>
  );
};

export default About;
