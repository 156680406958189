import React from "react";
import { AiOutlineCopyright } from "react-icons/ai";

const FooterBottom = () => {
  return (
    <div className="w-full bg-[#F5F5F3]">
      <div className="max-w-container mx-auto border-t-[1px] pt-6 pb-8">
        <p className="text-titleFont text-center flex items-center justify-center text-lightText text-sm">
          <span className="text-md mr-[1px] mt-[2px] md:mt-0 text-center">
            <AiOutlineCopyright />
          </span>
                   <p>
            Copyright 2024 | Morethan<span className="text-green-500">SPEC</span> | All Rights Reserved
          </p>
        </p>
      </div>
    </div>
  );
};

export default FooterBottom;