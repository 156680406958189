import React, { useState } from "react";
import { motion } from "framer-motion";
import { FaFacebook, FaYoutube, FaTwitter, FaInstagram } from "react-icons/fa";
import FooterListTitle from "./FooterListTitle";
import { paymentCard } from "../../../assets/images";
import Image from "../../designLayouts/Image";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [subscribed, setSubscribed] = useState(false);

  const handleSubscribe = (e) => {
    e.preventDefault();
    if (email && email.match(/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/)) {
      setSubscribed(true);
      setEmail("");
    }
  };

  return (
    <div className="w-full bg-[#F5F5F3] py-20">
      <div className="max-w-container mx-auto grid grid-cols-1 md:grid-cols-2 xl:grid-cols-6 px-4 gap-10">
        <div className="col-span-2">
          <FooterListTitle title={<span>More about Morethan<span className="text-green-500">SPEC</span></span>} />
          <div className="flex flex-col gap-6">
            <p className="text-base w-full xl:w-[80%]">
              We are committed to reducing environmental impact through innovative recycling 
              and refurbishment processes, ensuring tech products are revitalized to extend 
              their lifecycle and minimize waste.
            </p>
            <ul className="flex items-center gap-2">
              <a href="#" target="_blank" rel="noreferrer">
                <li className="w-7 h-7 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-black duration-300">
                  <FaYoutube />
                </li>
              </a>
              <a href="#" target="_blank" rel="noreferrer">
                <li className="w-7 h-7 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-black duration-300">
                  <FaTwitter />
                </li>
              </a>
              <a href="#" target="_blank" rel="noreferrer">
                <li className="w-7 h-7 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-black duration-300">
                  <FaFacebook />
                </li>
              </a>
              <a href="#" target="_blank" rel="noreferrer">
                <li className="w-7 h-7 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-black duration-300">
                  <FaInstagram />
                </li>
              </a>
            </ul>
          </div>
        </div>

        {/* Shop and Account sections remain the same */}

        <div className="col-span-2 flex flex-col items-center w-full px-4">
          <FooterListTitle title="Subscribe to Our Newsletter" />
          <div className="w-full">
            <p className="text-center mb-4">
              Subscribe to our newsletter for updates on sustainable tech and exclusive offers.
            </p>
            {subscribed ? (
              <motion.div
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                className="bg-green-50 p-4 rounded-lg"
              >
                <p className="text-center text-green-600 font-medium">
                  Thanks for subscribing! 🌱 Check your inbox soon.
                </p>
              </motion.div>
            ) : (
              <form onSubmit={handleSubscribe} className="flex flex-col gap-4">
                <div className="relative">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Your email address"
                    className="w-full px-4 py-3 rounded-lg bg-white border border-gray-200 focus:border-green-500 focus:ring-2 focus:ring-green-200 outline-none transition-all"
                    required
                  />
                  <button
                    type="submit"
                    className="absolute right-2 top-2 px-4 py-1.5 bg-primeColor text-white rounded-md hover:bg-black transition-colors duration-300"
                  >
                    Subscribe
                  </button>
                </div>
              </form>
            )}

            <div className="mt-6">
              <Image className="w-[80%] lg:w-[60%] mx-auto" imgSrc={paymentCard} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
